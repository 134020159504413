import { format } from 'd3-format';
import moment from 'moment-timezone';
import { formatBytes } from '../utils/math-utils';
import { secondsToHumanFriendlyFormatted } from '../utils/misc-utils';
import { interval } from './feature-services/feature-service/component/ChartDefaultParams';
import {
  ChartInstance,
  CustomGraph,
  GraphPanel,
  GraphRenderType,
  GraphType,
  QueryParam,
} from './feature-views/graph-types';
import { getMinutes, getValueUnit } from './graph-util';
const formatter2Digits = format('.2');
const percentFormatter2Digits = format('.2~%');
const percentFormatter3Digits = format('.3~%');
const percentFormatterBy4 = format('.4~%');
const floatFormatterBy3 = format('.3~f');
const floatFormatterBy2 = format('.2~f');

/** ------------------------- Feature View Monitoring Start ------------------------ */
// Application Path: /app/repo/{workspace}/features/monitoring-summary
export const FEATURE_VIEW_MONITORING_SUMMARY_GRAPH: GraphPanel[] = [
  {
    panelLabel: `Summary (::workspace::)`,
    graphs: [
      {
        chartTitle: `# of Feature Views and Table`,
        renderType: GraphRenderType.CUSTOM,
        customGraph: CustomGraph.NUMBER_OF_FEATURE_VIEWS_AND_TABLE,
      },
      {
        chartTitle: '# of Feature Views and Tables over time',
        yAxisLabel: 'Count',
        renderType: GraphRenderType.PROMETHEUS,
        showLegend: true,
        yTickCallBack: (value: number) => {
          return value;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          return `${label}: ${y}`;
        },
        queries: [
          {
            label: 'Total',
            query: (param: QueryParam) => {
              return `count  by (tecton_cluster) (feature_package_materialization_enabled{workspace=~'${param.workspace}',tecton_cluster=~'.*', aws_region=~'.*'}) OR on() vector(0)`;
            },
          },
          {
            label: 'Materialized',
            query: (param: QueryParam) => {
              return `sum by (tecton_cluster) (feature_package_materialization_enabled{workspace=~'${param.workspace}',tecton_cluster=~'.*', aws_region=~'.*'}) OR on() vector(0)`;
            },
          },
        ],
      },
    ],
  },
  {
    panelLabel: `Feature View Health`,
    graphs: [
      {
        chartTitle: '% of Feature Views below freshness threshold (::workspace::)',
        showLegend: false,
        yAxisLabel: '% of Total',
        renderType: GraphRenderType.PROMETHEUS,
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y } = context.parsed;

          // payload doesn't return the label so we use part of the query.
          return `Value: ${floatFormatterBy2(y)}`;
        },
        queries: [
          {
            label: '',
            query: (param: QueryParam) => {
              return `count by (tecton_cluster) (ALERTS{alertstate='firing', alertname='FeatureViewHighStaleness', tecton_cluster=~'.*', aws_region=~'.*'}) / sum by (tecton_cluster) (feature_package_materialization_enabled{workspace=~'${param.workspace}',tecton_cluster=~'.*', aws_region=~'.*'}) OR on() vector(0) * 100 OR on() vector(0)`;
            },
          },
        ],
        parseValue: parseFloat,
      },
      {
        chartTitle: `# of Feature Views with Materialization Failures (all workspaces)`,
        showLegend: false,
        yAxisLabel: '# of Feature Views',
        renderType: GraphRenderType.PROMETHEUS,
        yTickCallBack: (value: number) => {
          return value;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y } = context.parsed;
          let { label } = context.dataset;

          if (!label) {
            // payload doesn't return the label so we use part of the query.
            label = `Feature View Count`;
          }
          return `${label}: ${y}`;
        },
        queries: [
          {
            label: '',
            query: () => {
              return `count by (tecton_cluster, alertname) (ALERTS{alertstate='firing', alertname=~'BatchMaterializationTaskRecurrentFailures|StreamingMaterializationTaskRecurrentFailures|MaterializationTaskMovedToPermanentFailure|FeatureTableIngestTaskRecurrentFailures', tecton_cluster=~'.*', aws_region=~'.*'}) OR on() vector(0)`;
            },
          },
        ],
      },
    ],
  },
  {
    panelLabel: `Feature View Health Drilldown (all workspaces)`,
    graphs: [
      {
        chartTitle: `% of Feature Views below freshness threshold (all workspaces)`,
        renderType: GraphRenderType.CUSTOM,
        customGraph: CustomGraph.FEATURE_VIEW_DRILL_DOWN,
      },
    ],
  },
];

// Application Path: /app/repo/{workspace}/monitoring/slo-monitoring
const COMMON_FEATURE_VIEW_GRAPH: GraphType[] = [
  {
    chartTitle: 'Processed Event Age',
    showLegend: true,
    graphInfoTooltip: `Processed Event Age measures the difference between when a microbatch completes writing to the online store, and the timestamps of the features processed in that microbatch. This metric is an indication of how long it takes for an event to make it all the way to being ingested to the feature store. By definition, Processed Event Age will include both upstream processing time, in addition to the time taken by Tecton to transform and persist the event.`,
    yAxisLabel: '',
    renderType: GraphRenderType.PROMETHEUS,
    parseValue: parseFloat,
    queries: [
      {
        label: 'Most Recent',
        query: (param: QueryParam) => {
          return `sum by (tecton_cluster, workspace, feature_view_name, feature_view_id) (spark_online_store_processed_event_age_most_recent{feature_view_id=~"${param.fcoId}", tecton_cluster=~'.*'})`;
        },
      },
      {
        label: 'Least Recent',
        query: (param: QueryParam) => {
          return `sum by (tecton_cluster, workspace, feature_view_name, feature_view_id) (spark_online_store_processed_event_age_least_recent{feature_view_id=~"${param.fcoId}", tecton_cluster=~'.*'})`;
        },
      },
      {
        label: 'Average',
        query: (param: QueryParam) => {
          return `sum by (tecton_cluster, workspace, feature_view_name, feature_view_id) (spark_online_store_processed_event_age_average{feature_view_id=~"${param.fcoId}", tecton_cluster=~'.*'})`;
        },
      },
    ],
    yTickCallBack: (value: number) => {
      return `${value / 1000} s`;
    },
    toolTipLabelCallback: (context: any) => {
      const { y } = context.parsed;

      return `${context?.dataset?.label} ${y / 1000} s`;
    },
    getMetricLabelCallback: (result: any, metricName?: string) => {
      return `${metricName}`;
    },
  },
  {
    chartTitle: 'Input Rate',
    showLegend: true,
    graphInfoTooltip: 'Input Rate is the rate of messages read from the stream. Interval value is 5m',
    yAxisLabel: 'Input Records per Second',
    renderType: GraphRenderType.PROMETHEUS,
    parseValue: parseFloat,
    queries: [
      {
        label: 'Input Rate',
        query: (param: QueryParam) => {
          return `sum by (tecton_cluster,workspace,feature_view_name,feature_view_id)  (rate(spark_online_store_input_row_count{feature_view_id=~"${param.fcoId}", tecton_cluster=~'.*',  aws_region=~'.*'}[${param.step}s]))`;
        },
      },
    ],
    yTickCallBack: (value: number) => {
      return `${floatFormatterBy2(value)} rd/s`;
    },
    toolTipLabelCallback: (context: any) => {
      const { y } = context.parsed;
      const { dataset } = context;
      const labelText = `${dataset.label} : ${floatFormatterBy2(y)} rd/s`;
      return labelText;
    },
    getMetricLabelCallback: (result: any, metricName?: string) => {
      return `${metricName}`;
    },
  },
  {
    chartTitle: 'Online Store Write Rate',
    showLegend: true,
    yAxisLabel: 'Writes per Second',
    graphInfoTooltip: 'Rate of records being written to the online store.',
    renderType: GraphRenderType.PROMETHEUS,
    parseValue: parseFloat,
    queries: [
      {
        label: 'Online Store Write Rate',
        query: (param: QueryParam) => {
          return `sum by (tecton_cluster, workspace, feature_view_name, feature_view_id, materialization_task_type)  (rate(spark_online_store_sink_write_count_by_fv_sum_rollup{feature_view_id=~"${param.fcoId}", tecton_cluster=~'.*'}[120s]))`;
        },
      },
    ],
    yTickCallBack: (value: number) => {
      const formatter = format('.3f');
      return `${formatter(value)} wr/s`;
    },
    toolTipLabelCallback: (context: any) => {
      const formatter = format('.3f');
      const { y } = context.parsed;
      const labelText = `spark_online_store_sink_write_count_by_fv_sum_rollup: ${formatter(y)} wr/s`;
      return labelText;
    },
  },
  {
    chartTitle: 'Micro-batch Processing Latency',
    showLegend: true,
    yAxisLabel: 'Duration',
    graphInfoTooltip:
      'Processing latency for a single micro-batch. In steady-state, this number should remain below 30 seconds since Stream Feature Views micro-batches are 30 seconds long.',
    renderType: GraphRenderType.GRAPHITE,
    queries: [],
    parameters: (featureViewId: string) => {
      const query = `alias(removeEmptySeries(*.*.spark.*__${featureViewId}.driver.spark.streaming.tecton_osw_sink.latency), 'Latency')`;
      const params = [
        ['target', query],
        ['format', 'json'],
        ['maxDataPoints', '100'],
      ];

      return params;
    },
    yTickCallBack: (value: number) => {
      return `${value / 1000} s`;
    },
    toolTipLabelCallback: (context: any) => {
      const { y } = context.parsed;
      const labelText = ` ${y / 1000} s`;
      return labelText;
    },
  },
];

/** ------------------------- Specific Feature View Page Start ------------------------ */

export const COMMON_DYNAMODB_FEATURE_VIEW_STORE_TYPE: GraphPanel[] = [
  {
    panelLabel: `DynamoDB Metrics`, // ::workspace:: will be replaced with the current workspace
    graphs: [
      {
        chartTitle: 'DynamoDB Serving Query Latency',
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        showLegend: true,
        yTickCallBack: (value: number) => {
          if (value >= 1) return `${formatter2Digits(value)} s`;
          if (value * 1000 > 99) return `${formatter2Digits(value * 10)} ms`;

          return `${formatter2Digits(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;

          if (value >= 1) return `${formatter2Digits(value)} s`;
          if (value * 1000 > 99) {
            return `${formatter2Digits(value * 10)} ms`;
          }
          const labelText = `${label}: ${formatter2Digits(value * 1000)} ms`;
          return labelText;
        },
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
        ],
        parseValue: parseFloat,
      },
      {
        chartTitle: 'DynamoDB Row Count',
        yAxisLabel: 'Count',
        renderType: GraphRenderType.PROMETHEUS,
        showLegend: true,
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(dynamodb_rows_per_query_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(dynamodb_rows_per_query_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(dynamodb_rows_per_query_bucket_rollup_no_pod{table!~'^_status_.*',table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(dynamodb_rows_per_query_bucket_rollup_no_pod{table!~'^_status_.*', table=~'.*${param.fcoId}.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${floatFormatterBy2(value)}`;
          return labelText;
        },

        parseValue: parseFloat,
      },
    ],
  },
];

export const COMMON_REDIS_FEATURE_VIEW_STORE_TYPE: GraphPanel[] = [
  {
    panelLabel: `Redis Metrics`, // ::workspace:: will be replaced with the current workspace
    graphs: [
      {
        chartTitle: 'Redis Serving Query Latency',
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        showLegend: true,
        yTickCallBack: (value: number) => {
          if (value * 1000 > 99) {
            return `${formatter2Digits(value * 10)} ms`;
          }
          return `${formatter2Digits(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;

          if (value * 1000 > 99) {
            return `${formatter2Digits(value * 10)} ms`;
          }
          const labelText = `${label}: ${formatter2Digits(value * 1000)} ms`;
          return labelText;
        },
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
        ],
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Redis Row Count',
        yAxisLabel: 'Count',
        renderType: GraphRenderType.PROMETHEUS,
        showLegend: true,
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(redis_rows_per_query_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(redis_rows_per_query_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(redis_rows_per_query_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(redis_rows_per_query_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table!~'^s_.*', table=~'.*${param.fcoId}.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: ChartInstance) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${floatFormatterBy2(value)}`;
          return labelText;
        },

        parseValue: parseFloat,
      },
    ],
  },
];

// Application Path: /app/repo/{workspace}/features/monitoring
export const STREAM_FEATURE_VIEW_MONITORING_TAB: GraphPanel[] = [
  {
    panelLabel: 'Stream Materialization',
    graphs: [...COMMON_FEATURE_VIEW_GRAPH],
  },
  {
    panelLabel: 'Online Store',
    graphs: [
      {
        chartTitle: 'Online Serving Feature Freshness',
        showLegend: true,
        yAxisLabel: 'Freshness',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Feature freshness for data that is ready to serve from the online store.',
        queries: [
          {
            label: 'Online Serving Feature Freshness',
            query: (param: QueryParam) => {
              return `max(time() - (((status_reader_most_recent_ready_endtime_seconds{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}) > 0)))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${secondsToHumanFriendlyFormatted(value)} `;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Online Serving Feature Freshness: ${secondsToHumanFriendlyFormatted(y)} `;
        },
      },
      {
        chartTitle: 'Average Serving Delay',
        showLegend: true,
        yAxisLabel: 'Serving delay',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Serving delay for online store, average over the past ::step:: seconds',
        queries: [
          {
            label: 'Average Serving Delay',
            query: (param: QueryParam) => {
              return `max(avg_over_time((time() - (((status_reader_most_recent_ready_endtime_seconds{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}) > 0)))[${param.step}s:15s]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${secondsToHumanFriendlyFormatted(value)} `;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Average Serving Delay: ${secondsToHumanFriendlyFormatted(y)} `;
        },
      },
    ],
  },
  {
    panelLabel: 'Executors',
    graphs: [
      {
        chartTitle: 'Executor Total Memory Used',
        showLegend: false,
        yAxisLabel: '',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip:
          'Memory used by each executor (worker). This may include multiple timeseries since clusters can have > 1 executors.',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `aliasByNode(movingAverage(removeEmptySeries(exclude(*..*.spark.*__${featureViewId}.*.jvm.total.used, 'driver')), '5min'), 4, 1, 3)`;
          const params = [
            ['target', query],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];
          return params;
        },
        yTickCallBack: (value: number) => {
          if (value && value > 1) {
            const y = formatBytes(value, 0);
            return `${y} `;
          }
          return `${value} B`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const value = formatBytes(y, 0);

          const labelText = `${value}`;
          return labelText;
        },
      },
      {
        chartTitle: 'Executor Heap Usage',
        showLegend: false,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip:
          'Heap usage percentage by each executor (worker). This may include multiple timeseries since clusters can have > 1 executors.',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `aliasByNode(movingAverage(removeEmptySeries(exclude(*.*.spark.*__${featureViewId}.*.jvm.heap.usage, 'driver')), '5min'), 4)`;

          const params = [
            ['target', query],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];

          return params;
        },
        yTickCallBack: (value: number) => {
          return `${Math.round(value * 100)} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = ` ${Math.round(y * 100)} %`;

          return labelText;
        },
      },
    ],
  },
  {
    panelLabel: 'Drivers',
    graphs: [
      {
        chartTitle: 'Driver Total Memory Used',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip: 'Memory used by driver node.',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `alias(movingAverage(removeEmptySeries(*.*.spark.*__${featureViewId}.driver.jvm.total.used), '5min'), 'Driver')`;

          const params = [
            ['target', query],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];
          return params;
        },
        yTickCallBack: (value: number) => {
          const y = formatBytes(value, 0);
          return `${y}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const value = formatBytes(y, 0);

          const labelText = `${value}`;
          return labelText;
        },
      },
      {
        chartTitle: 'Driver Heap Usage',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip: 'Driver node heap usage percentage.',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `alias(movingAverage(removeEmptySeries(*.*.spark.*__${featureViewId}.driver.jvm.heap.usage), '5min'), 'Driver')`;
          const params = [
            ['target', query],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];

          return params;
        },
        yTickCallBack: (value: number) => {
          return `${Math.round(value * 100)} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = ` ${Math.round(y * 100)} %`;
          return labelText;
        },
      },
    ],
  },
  {
    panelLabel: 'Stream Watermark',
    graphs: [
      {
        chartTitle: 'Stream Watermark vs. Current Time',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip:
          'Latest stream event watermark compared to the current time. In steady-state, the delta between watermark and current time should be equal to the specified watermark delay threshold for a Feature View.',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `alias(removeBelowValue(*.*.spark.*__${featureViewId}.driver.spark.streaming.tecton_osw_sink.eventTime-watermark, 1), 'Event Watermark')`;
          const query2 = `alias(scale(time('now', 30), 1000), 'Current Timestamp')`;

          const params = [
            ['target', query],
            ['target', query2],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];
          return params;
        },
        yTickCallBack: (value: number) => {
          return `${moment(value).format('HH:MM A')}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const label = context?.dataset?.label ?? '';
          const labelText = `${label} : ${moment(y).format('MM/DD/YY HH:MM')}`;
          return labelText;
        },
        yOptions: {
          bounds: 'ticks',
          title: {
            display: true,

            font: {
              size: 12,
            },
          },
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              millisecond: 'MM/DD/YY HH:MM',
              second: 'MM/DD/YY HH:MM',
              minute: 'MM/DD/YY HH:MM',
              hour: 'MM/DD/YY HH:MM',
              day: 'MM/DD/YY HH:MM',
              quarter: 'MM/DD/YY HH:MM',
            },
          },
        },
      },
    ],
  },
  {
    panelLabel: 'State Storage',
    graphs: [
      {
        chartTitle: 'StateOperators - Rows Total',
        showLegend: false,
        yAxisLabel: '',
        yLabel: '',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip: 'Internal state storage: number of rows stored in executors',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `removeEmptySeries(*.*.spark.*__${featureViewId}.driver.spark.streaming.tecton_osw_sink.states-rowsTotal)`;

          const params = [
            ['target', query],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];

          return params;
        },
        yTickCallBack: (value: number) => {
          return `${value}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = ` ${y}`;

          return labelText;
        },
      },
      {
        chartTitle: 'StateOperators - Bytes Used',
        showLegend: false,
        yAxisLabel: '',
        yLabel: '',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip: 'Internal state storage: bytes stored in executors',
        queries: [],
        parameters: (featureViewId: string) => {
          const query = `removeEmptySeries(*.*.spark.*__${featureViewId}.driver.spark.streaming.tecton_osw_sink.states-usedBytes)`;

          const params = [
            ['target', query],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];

          return params;
        },
        yTickCallBack: (value: number) => {
          return `${value} B`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = ` ${y} B`;

          return labelText;
        },
      },
    ],
  },
];
export const BATCH_FEATURE_VIEW_MONITORING_TAB: GraphPanel[] = [
  {
    panelLabel: 'Serving',
    graphs: [
      {
        chartTitle: 'Online Store Write Rate',
        showLegend: true,
        yAxisLabel: 'Write per Second',
        renderType: GraphRenderType.GRAPHITE,
        graphInfoTooltip: 'Rate of records being written to the online store.',
        queries: [],
        parameters: (featureViewId: string) => {
          const sumSeries = `alias(scale(sumSeries(summarize(*.*.spark.*__${featureViewId}.*.tecton-online-store-writer.cm_feature_write_row_count.*.*.*.stream.data.*, '30s', 'sum', false)), 0.033), 'Online Feature Store Stream Write Rate')`;
          const sumSeries2 = `alias(scale(sumSeries(summarize(*.*.spark.*__${featureViewId}.*.tecton-online-store-writer.cm_feature_write_rows.*.stream.data.exception, '30s', 'sum', false)), 0.033), 'Online Feature Store Stream Exception Rate')`;
          const transformNull = `alias(scale(sumSeries(summarize(*.*.spark.*__${featureViewId}.*.tecton-online-store-writer.cm_feature_write_row_count.*.*.*.batch.data.*, '30s', 'sum', false)), 0.033), 'Online Feature Store Batch Write Rate')`;
          const transformNull2 = `alias(transformNull(scale(sumSeries(summarize(*.*.spark.*__${featureViewId}.*.tecton-online-store-writer.cm_feature_write_rows.*.batch.data.exception, '30s', 'sum', false)), 0.033), 0.000), 'Online Feature Store Batch Exception Rate')`;

          const params = [
            ['target', sumSeries],
            ['target', sumSeries2],
            ['target', transformNull],
            ['target', transformNull2],
            ['format', 'json'],
            ['maxDataPoints', '100'],
          ];

          return params;
        },
        yTickCallBack: (value: number) => {
          return `${value} wps`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = ` ${y}  wps`;
          return labelText;
        },
      },
      {
        chartTitle: 'Online Serving Feature Freshness',
        showLegend: true,
        yAxisLabel: 'Freshness',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Feature freshness for data that is ready to serve from the online store.',
        queries: [
          {
            label: 'Online Serving Feature Freshness',
            query: (param: QueryParam) => {
              return `max(time() - (((status_reader_most_recent_ready_endtime_seconds{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}) > 0)))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return secondsToHumanFriendlyFormatted(value);
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${secondsToHumanFriendlyFormatted(y.toFixed(2))}`;

          return labelText;
        },
      },
    ],
  },
];

const COMMON_FEATUREVIEW_SERVING_DELAY = [
  {
    chartTitle: 'Serving Delay [Continuous Features]',
    showLegend: true,
    label: '',
    yAxisLabel: 'Latency',
    renderType: GraphRenderType.PROMETHEUS,
    queries: [
      {
        label: '50.0 percentile',
        query: (param: QueryParam) => {
          return `histogram_quantile(0.5, sum(rate(status_reader_freshness_histogram_seconds_bucket_rollup_no_pod{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[5m])) by (le))`;
        },
      },
      {
        label: '90.0 percentile',
        query: (param: QueryParam) => {
          return `histogram_quantile(0.9, sum(rate(status_reader_freshness_histogram_seconds_bucket_rollup_no_pod{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[5m])) by (le))`;
        },
      },
      {
        label: '95.0 percentile',
        query: (param: QueryParam) => {
          return `histogram_quantile(0.95, sum(rate(status_reader_freshness_histogram_seconds_bucket_rollup_no_pod{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[5m])) by (le))`;
        },
      },
      {
        label: '99.0 percentile',
        query: (param: QueryParam) => {
          return `histogram_quantile(0.99, sum(rate(status_reader_freshness_histogram_seconds_bucket_rollup_no_pod{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[5m])) by (le))`;
        },
      },
    ],
    yTickCallBack: (value: number) => {
      return secondsToHumanFriendlyFormatted(value);
    },
    toolTipLabelCallback: (context: any) => {
      const { y } = context.parsed;
      const { dataset } = context;
      const labelText = `${dataset.label} : ${secondsToHumanFriendlyFormatted(y.toFixed(2))}`;
      return labelText;
    },
  },
];
export const CONTINUES_FEATURE_VIEW_MONITORING_TAB: GraphPanel[] = [
  {
    panelLabel: 'Streaming',
    graphs: [...COMMON_FEATURE_VIEW_GRAPH],
  },
  {
    panelLabel: 'Freshness',
    graphs: [
      ...COMMON_FEATUREVIEW_SERVING_DELAY,
      {
        chartTitle: 'Average Serving Delay',
        showLegend: true,
        yAxisLabel: 'Serving delay',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Serving delay for online store, average over the past ::step:: seconds',
        queries: [
          {
            label: 'Average Serving Delay',
            query: (param: QueryParam) => {
              return `max(avg_over_time((time() - (((status_reader_most_recent_ready_endtime_seconds{feature_view_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}) > 0)))[${param.step}s:15s]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${secondsToHumanFriendlyFormatted(value)} `;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Average Serving Delay: ${secondsToHumanFriendlyFormatted(y)} `;
        },
      },
    ],
  },
];
/**
 *  https://tecton.chronosphere.io/v3/dashboards/ingest-stream-feature-view/ingest-stream-feature-view-dashboard?orgId=1&refresh=5m&var-tecton_cluster=tecton-staging&var-feature_view_id=All&var-aws_region=All
 */
export const PUSH_FEATURE_VIEW_MONITORING_TAB: GraphPanel[] = [
  {
    panelLabel: 'Batch Metrics',
    graphs: [
      {
        chartTitle: 'Average Batch Size',
        showLegend: true,
        yAxisLabel: 'Batch Size',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Average Batch Size per request to the IngestAPI for the Feature View',
        queries: [
          {
            label: 'Average Batch Size',
            // HACK: The below query uses the count metric from a histogram to account for the lack of a general
            // request count metric in the serverful ingest API metrics
            query: (param: QueryParam) => {
              return `((sum(rate(ingestapi_online_batch_size{feature_view_id=~'${param.fcoId}'}[5m])) /
               sum(rate(ingestapi_online_batch_write_latency_count{feature_view_id=~'${param.fcoId}'}[5m]))) or vector(0))
               + (max(ingestapi_batch_size_average{feature_view_id=~'${param.fcoId}'}) or vector(0))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          const formatter = format('.2f');
          return `${formatter(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const formatter = format('.2f');
          return `Batch Size: ${formatter(y)}`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Online Row Write Rate',
        showLegend: true,
        yAxisLabel: 'Rows Written/Second',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '',
            query: (param: QueryParam) => {
              return `(sum(rate(ingestapi_online_row_count{feature_view_id=~'${param.fcoId}'}[5m])) or  vector(0))
              + (sum(ingestapi_online_ingest_row_write_count{feature_view_id=~'${param.fcoId}'}) / 60 or  vector(0))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value.toFixed(2)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Batch Size: ${y.toFixed(2)}`;
        },
        getMetricLabelCallback: (result: any) => {
          return `Batch Size`;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Online Ingest Duration Metrics',
    graphs: [
      {
        chartTitle: 'Request Duration',
        showLegend: true,
        yAxisLabel: 'Duration',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip:
          'Total Request Duration. Note that this is calculated across all requests to the Stream Ingest API, and not per Feature View',
        queries: [
          {
            label: '50.0 percentile',
            query: ({ ingressType }) => {
              // TODO: Delete this logic when migration to ELB is done.
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p50_00{tecton_cluster=~'.*',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p50_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;
              return query;
            },
          },
          {
            label: '90.0 percentile',
            query: ({ ingressType }) => {
              // TODO: Delete this logic when migration to ELB is done.
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p90_00{tecton_cluster=~'.*', aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p90_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;
              return query;
            },
          },
          {
            label: '95.0 percentile',
            query: ({ ingressType }) => {
              // TODO: Delete this logic when migration to ELB is done.
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p95_00{tecton_cluster=~'.*', aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p95_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;
              return query;
            },
          },
          {
            label: '99.0 percentile',
            query: ({ ingressType }) => {
              // TODO: Delete this logic when migration to ELB is done.
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p99_00{tecton_cluster=~'.*', aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p99_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value.toFixed(2)} s`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `${context?.dataset?.label}: ${y.toFixed(2)} s`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Batch Online Write Latency',
        showLegend: true,
        yAxisLabel: 'Online Write Latency',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Average Latency for Online Writes per batch of records for a Feature View',
        queries: [
          {
            label: 'Latency',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_online_write_latency_average{feature_view_id=~'${param.fcoId}'}) or vector(0))
              + (
                  (
                    sum(rate(ingestapi_online_batch_write_latency_sum{feature_view_id=~'${param.fcoId}'}[5m]))
                    / (1+sum(rate(ingestapi_online_batch_write_latency_count{feature_view_id=~'${param.fcoId}'}[5m])))
                  )
                or vector(0)
              )`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Latency: ${y} ms`;
        },
      },
    ],
  },
  {
    panelLabel: 'Task Latency Breakdown Metrics',
    graphs: [
      {
        chartTitle: 'Batch Validation Latency',
        showLegend: false,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Average Latency for Validations per batch of records for a Feature View',
        queries: [
          {
            label: '',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_validation_latency_average{feature_view_id=~'${param.fcoId}'}) or vector(0))
              + (
                  (
                    sum(rate(ingestapi_batch_validation_latency_sum{feature_view_id=~'${param.fcoId}'}[5m]))
                    / (1+sum(rate(ingestapi_batch_validation_latency_count{feature_view_id=~'${param.fcoId}'}[5m])))
                  )
                or vector(0)
              )`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Latency: ${y} ms`;
        },
      },
      {
        chartTitle: 'Batch Aggregation Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Average Latency for Aggregations per batch of records for a Feature View',
        queries: [
          {
            label: '',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_aggregation_latency_average{feature_view_id=~'${param.fcoId}'}) or vector(0))
              + (
                  (
                    sum(rate(ingestapi_aggregation_batch_latency_sum{feature_view_id=~'${param.fcoId}'}[5m]))
                    / (1+sum(rate(ingestapi_aggregation_batch_latency_count{feature_view_id=~'${param.fcoId}'}[5m])))
                  )
                or vector(0)
              )`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value.toFixed(2)} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `Latency: ${y.toFixed(2)} ms`;
        },
        getMetricLabelCallback: (result: any) => {
          return `Latency`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Batch Transformation Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: 'Transformation Latency per batch of records for a Feature View',
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_transformation_latency_p50_00{feature_view_id=~"${param.fcoId}"}) or vector(0))
              + (
              histogram_quantile(0.5, sum(rate(ingestapi_transformation_batch_latency_bucket{feature_view_id=~"${param.fcoId}"}[5m])) by (le))
              or vector(0))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_transformation_latency_p90_00{feature_view_id=~"${param.fcoId}"}) or vector(0))
              + (
              histogram_quantile(0.9, sum(rate(ingestapi_transformation_batch_latency_bucket{feature_view_id=~"${param.fcoId}"}[5m])) by (le))
              or vector(0))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_transformation_latency_p95_00{feature_view_id=~"${param.fcoId}"}) or vector(0))
              + (
              histogram_quantile(0.95, sum(rate(ingestapi_transformation_batch_latency_bucket{feature_view_id=~"${param.fcoId}"}[5m])) by (le))
              or vector(0))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `(avg(ingestapi_batch_transformation_latency_p99_00{feature_view_id=~"${param.fcoId}"}) or vector(0))
              + (
              histogram_quantile(0.99, sum(rate(ingestapi_transformation_batch_latency_bucket{feature_view_id=~"${param.fcoId}"}[5m])) by (le))
              or vector(0))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          return `${context?.dataset?.label}: ${floatFormatterBy2(y)} ms`;
        },
        parseValue: parseFloat,
      },
    ],
  },
];
/** ------------------------- Specific Feature View Page End ------------------------ */

/**  ------------------------- Feature Service Start ------------------------
 *  Application Path: /app/repo/{workspace}/feature-services/{service_name}/monitoring
 */

// We register graph panel label here which needs logic to hide/show.
export enum FILTERABLE_PANEL_LABEL {
  GET_FEATURES_BATCH = 'get-features-batch',
  TECTON_SERVING_CACHE = 'Tecton Serving Cache',
  FEATURE_SERVICE_DYNAMO_GRAPHS = 'Online Store Latency',
}

export const FEATURE_SERVICE_GRAPHS: GraphPanel[] = [
  {
    panelLabel: 'get-features',
    graphs: [
      {
        chartTitle: 'Online Feature Queries',
        showLegend: true,
        yAxisLabel: 'Queries per Second',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: `Interval value is ${interval}`,
        queries: [
          {
            label: 'Invalid Argument',
            query: (param: QueryParam) => {
              return `sum by (status) (rate(feature_service_query_count{job=~".*feature-server.*", feature_service_id="${param.fcoId}", method="GetFeatures", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)} qps`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${floatFormatterBy2(y)} qps`;

          return labelText;
        },
        getMetricLabelCallback: (result: any) => {
          return result.metric.status;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Online Success Rate',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: 'Success Rate',
            query: (param: QueryParam) => {
              return `sum(rate(feature_service_query_count{job=~".*feature-server.*", feature_service_id="${param.fcoId}", method="GetFeatures", status="OK", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) / sum(rate(feature_service_query_count{job=~".*feature-server.*", feature_service_id="${param.fcoId}", method="GetFeatures", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${percentFormatterBy4(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `Online Success Rate: ${percentFormatterBy4(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Online Query Latency Distribution',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", method="GetFeatures", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", method="GetFeatures", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", method="GetFeatures", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", method="GetFeatures", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${Math.round(value * 10000) / 10} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${Math.round(y * 10000) / 10} ms`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Response Size Per Query',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(total_response_size_per_query_by_fs_bucket_rollup_no_pod{feature_service_id=~"${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(total_response_size_per_query_by_fs_bucket_rollup_no_pod{feature_service_id=~"${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(total_response_size_per_query_by_fs_bucket_rollup_no_pod{feature_service_id=~"${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(total_response_size_per_query_by_fs_bucket_rollup_no_pod{feature_service_id=~"${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${formatBytes(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${formatBytes(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Online Store Latency',
    graphs: [
      {
        chartTitle: FILTERABLE_PANEL_LABEL.FEATURE_SERVICE_DYNAMO_GRAPHS,
        renderType: GraphRenderType.CUSTOM,
        customGraph: CustomGraph.FEATURE_SERVICE_PAGE_MONITORING_ONLINE_STORE_LATENCY,
      },
    ],
    gridTemplateColumns: '1fr',
  },
  {
    panelLabel: FILTERABLE_PANEL_LABEL.GET_FEATURES_BATCH,
    graphs: [
      {
        chartTitle: 'Batch Queries',
        showLegend: false,
        yAxisLabel: 'Queries per Second',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '',
            query: (param: QueryParam) => {
              return `sum by (status) (rate(feature_service_batch_query_count{job=~".*feature-server.*", feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)} qps`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${floatFormatterBy2(y)} qps`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Online Success Rate',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: 'Success Rate',
            query: (param: QueryParam) => {
              return `sum(rate(feature_service_batch_query_count{job=~".*feature-server.*", feature_service_id="${param.fcoId}", status="OK", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) / sum(rate(feature_service_batch_query_count{job=~".*feature-server.*", feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value * 100} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${y.toFixed(4) * 100} %`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Feature Service Query Batch Size',
        showLegend: true,
        yAxisLabel: 'Keys',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(feature_service_query_batch_size_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(feature_service_query_batch_size_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.5 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(feature_service_query_batch_size_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(feature_service_query_batch_size_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${floatFormatterBy2(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Batch Query Latency Distribution',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(feature_service_batch_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(feature_service_batch_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.5 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(feature_service_batch_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(feature_service_batch_query_latency_seconds_bucket_rollup_no_pod{feature_service_id="${param.fcoId}", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${formatter2Digits(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${formatter2Digits(value * 1000)} ms`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Proportion of queries counting against the reliability SLO',
    graphs: [
      {
        chartTitle: 'Proportion of queries counting against the reliability SLO',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '% missing reliability SLO',
            query: (param: QueryParam) => {
              return `(sum(rate(feature_service_query_count{feature_service_id="${param.fcoId}", status=~"Unknown|Unimplemented|Internal|Unavailable|DataLoss", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) or on() vector(0)) / sum(rate(feature_service_query_count{feature_service_id="${param.fcoId}", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}:  ${floatFormatterBy2(y)} %`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: `Reliability SLO (30 days)`,
        renderType: GraphRenderType.CUSTOM,
        customGraph: CustomGraph.FEATURE_SERVICE_SLO_RELIABILITY,
      },
    ],
  },
  {
    panelLabel: 'Proportion of queries by Latency SLO status',
    graphs: [
      {
        chartTitle: 'Proportion of queries by Latency SLO status',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '% Not SLO-Eligible',
            query: (param: QueryParam) => {
              return `(sum(rate(feature_service_latency_slo_count{feature_service_id="${param.fcoId}", latency_slo_eligible="false", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) or on() vector(0)) / sum(rate(feature_service_latency_slo_count{feature_service_id="${param.fcoId}", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}]))`;
            },
          },
          {
            label: '% eligible and missed SLO',
            query: (param: QueryParam) => {
              return `(sum(rate(feature_service_latency_slo_count{feature_service_id="${param.fcoId}", latency_slo_eligible="true", latency_slo_met="false", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) or on() vector(0)) / sum(rate(feature_service_latency_slo_count{feature_service_id="${param.fcoId}", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${Math.round(value * 1000000) / 10000} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${Math.round(y * 1000000) / 10000} %`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: `Latency SLO (30 days)`,
        renderType: GraphRenderType.CUSTOM,
        customGraph: CustomGraph.FEATURE_SERVICE_SLO_LATENCY,
      },
    ],
  },
  {
    panelLabel: FILTERABLE_PANEL_LABEL.TECTON_SERVING_CACHE,
    graphs: [
      {
        chartTitle: 'Cache Hit Rate',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: '',
            query: (param: QueryParam) => {
              return `sum(rate(feature_view_cache_response_rollup_sum_no_pod{ aws_region=~'.*', tecton_cluster=~'.*', status='hit'}[${interval}])) by (feature_service_name, feature_view_name, tecton_cluster, region) / (sum(rate(feature_view_cache_response_rollup_sum_no_pod{ aws_region=~'.*', tecton_cluster=~'.*', status='hit'}[${interval}])) by (feature_service_name, feature_view_name, tecton_cluster, region) + sum(rate(feature_view_cache_response_rollup_sum_no_pod{status='miss',  aws_region=~'.*', tecton_cluster=~'.*'}[${interval}])) by (feature_service_name, feature_view_name, tecton_cluster, region))`;
            },
          },
        ],
        yTickCallBack: (value: any) => {
          return `${value.toFixed(4) * 100} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;

          const labelText = `${label}: ${y.toFixed(4) * 100} %`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
];

export const FEATURE_SERVICE_GRAPHS_FOR_ODFV_ONLY: GraphPanel[] = [
  {
    panelLabel: 'Latency',
    graphs: [
      {
        chartTitle: 'On Demand Transformation Latency (Including Network RTT)',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(on_demand_transform_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(on_demand_transform_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(on_demand_transform_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(on_demand_transform_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return (Math.round(value * 100000) / 100).toFixed(0) + ' ms';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${(Math.round(y * 100000) / 100).toFixed(2)} ms`;

          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'On Demand User Code Execution Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(on_demand_execution_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(on_demand_execution_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(on_demand_execution_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(on_demand_execution_latency_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return (Math.round(value * 100000) / 100).toFixed(0) + ' ms';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${(Math.round(y * 100000) / 100).toFixed(2)} ms`;

          return labelText;
        },

        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Payload Sizes',
    graphs: [
      {
        chartTitle: 'On Demand Transform Request Payload Size',
        showLegend: true,
        yAxisLabel: 'Bytes',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(on_demand_request_payload_size_bucket_rollup_no_pod{feature_service_id=~'${param.fcoId}', tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(on_demand_request_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(on_demand_request_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(on_demand_request_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          const y = formatBytes(value, 0);
          return `${y}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;

          const value = formatBytes(y, 0);

          const labelText = `${dataset.label} : ${value}`;
          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'On Demand Transform Response Payload Size',
        showLegend: true,
        yAxisLabel: 'Bytes',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.5, sum(rate(on_demand_response_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.9, sum(rate(on_demand_response_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.95, sum(rate(on_demand_response_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: (param: QueryParam) => {
              return `histogram_quantile(0.99, sum(rate(on_demand_response_payload_size_bucket_rollup_no_pod{tecton_cluster=~'.*', feature_service_id=~'${param.fcoId}',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          const y = formatBytes(value, 0);
          return `${y}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const value = formatBytes(y, 0);

          const labelText = `${dataset.label} : ${value}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
];

/**  ------------------------- Feature Service Start ------------------------ */
// /app/repo/{workspace}/monitoring/slo-monitoring
export const FEATURE_SERVICE_GLOBAL_SLO: GraphPanel[] = [
  {
    panelLabel: 'Feature Serving Reliability SLO',
    graphs: [
      {
        chartTitle: 'Proportion of queries counting against the reliability SLO',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '% missing reliability SLO',
            query: () => {
              return `(sum(rate(feature_service_query_count{status=~"Unknown|Unimplemented|Internal|Unavailable|DataLoss", tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) or on() vector(0)) / sum(rate(feature_service_query_count{tecton_cluster=~'.*',  aws_region=~'.*'}[10m]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return percentFormatter2Digits(value);
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `Proportion of queries counting against the reliability SLO: ${getMinutes(y) + ' %'}`;
          return labelText;
        },
      },
      {
        chartTitle: `Reliability SLO (30 days)`,
        renderType: GraphRenderType.CUSTOM,
        customGraph: CustomGraph.FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY,
      },
    ],
  },
];

export const TECTON_FEATURE_SERVING_CACHE_TITLE = 'Tecton Feature Serving Cache Monitoring';

// /app/repo/prod/monitoring/feature-service-monitoring
export const FEATURE_SERVICE_GLOBAL_MONITORING: GraphPanel[] = [
  {
    panelLabel: 'Online Serving Requests',
    graphs: [
      {
        chartTitle: 'Queries Per Second',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Total Online Serving QPS',
            query: () => {
              return `sum by (aws_region) (rate(grpc_server_handled_total_feature_server_rollup_no_job_instance{grpc_method="GetFeatures", tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy3(value)} qps`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `${floatFormatterBy3(y)} qps`;
          return labelText;
        },
        getMetricLabelCallback: (result: any) => {
          return result.metric.aws_region;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'HTTP Request Error Percentage by Status',
        showLegend: true,
        yAxisLabel: 'Error %',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ ingressType }) => {
              // TODO: Delete this logic when migration to ELB is done.
              const query =
                ingressType === 'alb'
                  ? `(sum by (status) (rate(label_replace({__name__=~"aws_applicationelb_httpcode_target_(4|5)_xx_count_sum", target_group=~'.*tecton-features.*', tecton_cluster=~'.*'}, "status", "HTTP $1.xx", "__name__", "aws_applicationelb_httpcode_target_(4|5)_xx_count_sum")[${interval}:]))) / ignoring (status) group_left sum(rate(aws_applicationelb_request_count_sum{target_group=~'.*tecton-features.*'}[${interval}:]))`
                  : `sum(rate(nginx_ingress_controller_requests{ingress='feature-server',status!='200', tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (status) / ignoring(status) group_left sum(rate(nginx_ingress_controller_requests{ingress='feature-server', tecton_cluster=~'.*', aws_region=~'.*'}[${interval}]))`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${percentFormatter3Digits(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;
          return `${label}: ${percentFormatter3Digits(value)}`;
        },
        getMetricLabelCallback: (result: any) => {
          return result?.metric?.status;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Feature Server Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: () => {
              return `histogram_quantile(0.5, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{method=~'GetFeatures', tecton_cluster=~'.*', aws_region=~'.*', tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: () => {
              return `histogram_quantile(0.9, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{method=~'GetFeatures', tecton_cluster=~'.*', aws_region=~'.*', tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: () => {
              return `histogram_quantile(0.95, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{method=~'GetFeatures', tecton_cluster=~'.*', aws_region=~'.*', tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: () => {
              return `histogram_quantile(0.99, sum(rate(feature_service_query_latency_seconds_bucket_rollup_no_pod{method=~'GetFeatures', tecton_cluster=~'.*', aws_region=~'.*', tecton_cluster=~'.*',  aws_region=~'.*'}[${interval}])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return (Math.round(value * 100000) / 100).toFixed(0) + ' ms';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${(Math.round(y * 100000) / 100).toFixed(2)} ms`;

          return labelText;
        },

        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Online Serving Capacity',
    graphs: [
      {
        chartTitle: 'Concurrent Request Utilization',
        showLegend: true,
        yAxisLabel: 'Utilization',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Concurrent Request Utilization',
            query: () => {
              return `max(concurrent_requests_max_percentage{tecton_cluster=~'.*',  aws_region=~'.*', instance=~"tecton/feature-server-[a-z0-9A-Z]*-[a-z0-9A-Z]*"})`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value.toFixed(0)}%`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;

          return `${label}: ${y.toFixed(0)}%`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Online Serving Pod Count by Status',
        showLegend: true,
        yAxisLabel: 'Count',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Ready Pods',
            query: () => {
              return `(sum(kube_deployment_status_replicas_ready{tecton_cluster=~'.*',  aws_region=~'.*', deployment=~"feature-server"}))`;
            },
          },
          {
            label: 'Available Pods',
            query: () => {
              return `(sum(kube_deployment_status_replicas_available{aws_region=~'.*', deployment=~"feature-server", tecton_cluster=~'.*'}))`;
            },
          },
          {
            label: 'Unavailable Pods',
            query: () => {
              return `sum(kube_deployment_status_replicas_unavailable{aws_region=~'.*', deployment=~"feature-server", tecton_cluster=~'.*'})`;
            },
          },
          {
            label: 'Set Feature Server Config',
            query: () => {
              return `sum(rate(grpc_server_handled_total{grpc_service="tecton_proto.metadataservice.MetadataService",  aws_region=~'.*', tecton_cluster=~'.*', grpc_method="SetFeatureServerConfig"}[15m])) by (tecton_cluster)`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${floatFormatterBy2(y)}`;

          return labelText;
        },
        getMetricLabelCallback: (result: any, metricName?: string) => {
          if (metricName && result?.metric?.aws_region) {
            return `${metricName}: ${result.metric.aws_region}`;
          }
          return metricName ?? '';
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Concurrent Request Utilization',
        showLegend: true,
        yAxisLabel: 'Request % of Limit per Pod',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Min Percentage of Limit Per Pod',
            query: () => {
              return `min(concurrent_requests_max_percentage{instance=~"tecton/feature-server-[a-z0-9A-Z]*-[a-z0-9A-Z]*", tecton_cluster=~'.*',  aws_region=~'.*'})`;
            },
          },
          {
            label: 'Avg Percentage of Limit Per Pod',
            query: () => {
              return `avg(concurrent_requests_max_percentage{instance=~"tecton/feature-server-[a-z0-9A-Z]*-[a-z0-9A-Z]*", tecton_cluster=~'.*',  aws_region=~'.*'})`;
            },
          },
          {
            label: 'Max Percentage of Limit Per Pod',
            query: () => {
              return `max(concurrent_requests_max_percentage{instance=~"tecton/feature-server-[a-z0-9A-Z]*-[a-z0-9A-Z]*", tecton_cluster=~'.*',  aws_region=~'.*'})`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value}%`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { label } = context.dataset;
          const labelText = `${label}: ${y}%`;

          return labelText;
        },
      },
    ],
  },
  {
    panelLabel: 'DynamoDB Monitoring',
    graphs: [
      {
        chartTitle: 'DynamoDB Serving Query Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50 percentile',
            query: () => {
              return `histogram_quantile(0.5, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'.*_status.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '90 percentile',
            query: () => {
              return `histogram_quantile(0.9, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'.*_status.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '95 percentile',
            query: () => {
              return `histogram_quantile(0.95, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'.*_status.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '99 percentile',
            query: () => {
              return `histogram_quantile(0.99, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{table!~'.*_status.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return value * 1000 + ' ms';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 1000 * 100) / 100} ms`;
          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'DynamoDB Serving QPS',
        showLegend: true,
        yAxisLabel: 'Queries per Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'DynamoDB Serving QPS',
            query: () => {
              return `sum by (aws_region)  (rate(dynamodb_query_count_feature_server_rollup_no_job_instance{tecton_cluster=~'.*',  aws_region=~'.*'}[15m]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return value + ' qps';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 100) / 100} qps`;
          return labelText;
        },

        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Redis Monitoring',
    graphs: [
      {
        chartTitle: 'Redis Serving Query Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50 percentile',
            query: () => {
              return `histogram_quantile(0.5, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{table!~'^s_.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '90 percentile',
            query: () => {
              return `histogram_quantile(0.9, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{table!~'^s_.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '95 percentile',
            query: () => {
              return `histogram_quantile(0.95, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{table!~'^s_.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
          {
            label: '99 percentile',
            query: () => {
              return `histogram_quantile(0.99, sum(rate(redis_query_latency_seconds_bucket_rollup_no_pod{table!~'^s_.*', tecton_cluster=~'.*',  aws_region=~'.*'}[15m])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${Math.round(value * 1000 * 100) / 100} ms`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 1000 * 100) / 100} ms`;
          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'Redis Serving QPS',
        showLegend: true,
        yAxisLabel: 'Queries per Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Redis Serving QPS',
            query: () => {
              return `sum by (aws_region)  (rate(redis_query_count_feature_server_rollup_no_job_instance{tecton_cluster=~'.*',  aws_region=~'.*'}[15m]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy3(value)} qps`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `${floatFormatterBy3(y)} qps`;
          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'Active primary / replica nodes count',
        showLegend: true,
        yAxisLabel: 'Nodes',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Primary Count',
            query: () => {
              return `max by (tecton_cluster, cluster_address) (redis_cluster_primary_nodes_num{tecton_cluster=~'.*', aws_region=~'.*', cluster_address=~'.*'})`;
            },
          },
          {
            label: 'Replicas Count',
            query: () => {
              return `max by (tecton_cluster, cluster_address) (redis_cluster_replica_nodes_num{tecton_cluster=~'.*', aws_region=~'.*', cluster_address=~'.*'})`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${y}`;

          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'Total number of keys',
        showLegend: true,
        yAxisLabel: 'Nodes',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: () => {
              return `sum(max by(address, cluster_address) (redis_cluster_node_keys_gauge{tecton_cluster=~'.*', aws_region=~'.*', cluster_address=~'.*'}))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${getValueUnit(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${getValueUnit(y)}`;
          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'Memory Usage per node',
        showLegend: true,
        yAxisLabel: '',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: () => {
              return `max by (address, cluster_address) (redis_cluster_node_memory_usage{tecton_cluster=~'.*', aws_region=~'.*', cluster_address=~'.*'})`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${formatBytes(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${formatBytes(y)}`;

          return labelText;
        },
        getMetricLabelCallback: (result: any, metricName?: string) => {
          return result.metric.cluster_address;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Errors',
    graphs: [
      {
        chartTitle: 'HTTP Request Error Count by Status',
        showLegend: true,
        yAxisLabel: 'Error count',
        graphInfoTooltip: 'Percentage of errors of write rate.',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `(sum by (status) (increase(label_replace({__name__=~"aws_applicationelb_httpcode_target_(4|5)_xx_count_sum", target_group=~'.*tecton-features.*', tecton_cluster=~'.*'}, "status", "HTTP $1.xx", "__name__", "aws_applicationelb_httpcode_target_(4|5)_xx_count_sum")[${interval}:])))`
                  : `sum(increase(nginx_ingress_controller_requests{tecton_cluster=~'.*', ingress='feature-server',status!='200', aws_region=~'.*'}[${interval}])) by (status)`;

              return query;
            },
          },
        ],
        getMetricLabelCallback: (result: any) => {
          return `Status ${result.metric.status}`;
        },
        yTickCallBack: (value: number) => {
          return `${value}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;

          return `Status ${context.dataset.label}: ${y}`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Request Error Rate by Feature Service',
        showLegend: true,
        yAxisLabel: 'Error Rate by Feature Service',
        renderType: GraphRenderType.PROMETHEUS,
        graphInfoTooltip: '',
        queries: [
          {
            label: 'Success Rate',
            query: () => {
              return `sum(rate(feature_service_query_count{job=~".*feature-server.*", status!="OK", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}])) by (status, feature_service_name) /ignoring(status, feature_service_name) group_left sum(rate(feature_service_query_count{job=~".*feature-server.*", tecton_cluster=~'.*', aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${percentFormatter3Digits(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y: value } = context.parsed;
          const { label } = context.dataset;
          return `${label}: ${percentFormatter3Digits(value)}`;
        },
        getMetricLabelCallback: (result: any) => {
          return `${result?.metric?.feature_service_name} - ${result?.metric?.status}`;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: TECTON_FEATURE_SERVING_CACHE_TITLE,
    graphs: [
      {
        chartTitle: 'Active primary / replica nodes count',
        showLegend: true,
        yAxisLabel: 'Nodes',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Primary Count',
            query: () => {
              return `max by (tecton_cluster, cluster_address) (redis_cluster_primary_nodes_num{tecton_cluster=~'.*', is_cache_cluster="true",  aws_region=~'.*'})`;
            },
          },
          {
            label: 'Replicas Count',
            query: () => {
              return `max by (tecton_cluster, cluster_address) (redis_cluster_replica_nodes_num{tecton_cluster=~'.*', is_cache_cluster="true", aws_region=~'.*'})`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${y}`;
          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'Memory Usage Per Node',
        showLegend: true,
        yAxisLabel: 'Percentage of max memory',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Primary Count',
            query: () => {
              return `aws_elasticache_database_memory_usage_percentage_average{tecton_cluster=~'.*', aws_region=~'.*'}`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${y} %`;

          return labelText;
        },

        parseValue: parseFloat,
      },
      {
        chartTitle: 'Engine Cpu Utilization Per Node',
        showLegend: true,
        yAxisLabel: 'Percent',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: () => {
              return `aws_elasticache_engine_cpuutilization_average{tecton_cluster=~'.*', aws_region=~'.*', cache_cluster_id=~'.*cache.*'}`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} %`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${y} %`;

          return labelText;
        },
        getMetricLabelCallback: (result: any) => {
          return result.metric.cache_cluster_id;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Total Cache QPS',
        showLegend: true,
        yAxisLabel: 'Queries per Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Total Cache QPS',
            query: () => {
              return `sum  by (tecton_cluster, aws_region) (rate(cache_requests_rollup_sum_no_pod{tecton_cluster=~'.*'}[5m]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return value + ' qps';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 100) / 100} qps`;

          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Cache Qps By Operation',
        showLegend: true,
        yAxisLabel: 'Queries per Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Read Cache QPS',
            query: () => {
              return `sum by (tecton_cluster, aws_region) (rate(cache_requests_rollup_sum_no_pod{tecton_cluster=~'.*', operation='read'}[5m]))`;
            },
          },
          {
            label: 'Write Cache QPS',
            query: () => {
              return `sum by (tecton_cluster, aws_region) (rate(cache_requests_rollup_sum_no_pod{tecton_cluster=~'.*', operation='write'}[5m]))`;
            },
          },
          {
            label: 'Expire Cache QPS',
            query: () => {
              return `sum by (tecton_cluster, aws_region) (rate(cache_requests_rollup_sum_no_pod{tecton_cluster=~'.*', operation='expire'}[5m]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return value + ' qps';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 100) / 100} qps`;

          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Cache Write Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: () => {
              return `histogram_quantile(0.5, sum(rate(cache_write_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: () => {
              return `histogram_quantile(0.9, sum(rate(cache_write_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: () => {
              return `histogram_quantile(0.95, sum(rate(cache_write_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: () => {
              return `histogram_quantile(0.99, sum(rate(cache_write_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return Math.round(value * 1000 * 100) / 100 + ' ms';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 1000 * 100) / 100} ms`;

          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Cache Read Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: () => {
              return `histogram_quantile(0.5, sum(rate(cache_read_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
          {
            label: '90.0 percentile',
            query: () => {
              return `histogram_quantile(0.9, sum(rate(cache_read_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
          {
            label: '95.0 percentile',
            query: () => {
              return `histogram_quantile(0.95, sum(rate(cache_read_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
          {
            label: '99.0 percentile',
            query: () => {
              return `histogram_quantile(0.99, sum(rate(cache_read_duration_bucket_rollup_sum_no_pod{tecton_cluster=~'.*',  aws_region=~'.*'}[10m])) by (le))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return Math.round(value * 1000 * 100) / 100 + ' ms';
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label} : ${Math.round(y * 1000 * 100) / 100} ms`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
];

// /app/repo/{workspace}/monitoring/stream-ingest-monitoring
export const INGEST_API_METRIC: GraphPanel[] = [
  {
    panelLabel: 'Stream Ingest API Requests',
    graphs: [
      {
        chartTitle: 'Stream Ingest API Queries per Second',
        showLegend: true,
        yAxisLabel: 'Request/Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Requests',
            query: ({ ingressType }) => {
              // TODO: Delete this logic when migration to ELB is done.
              const query =
                ingressType === 'alb'
                  ? `max by (tecton_cluster) (aws_applicationelb_request_count_sum{tecton_cluster=~'.*', aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) / 60`
                  : `max by (tecton_cluster) (aws_applicationelb_request_count_sum{tecton_cluster=~'.*', aws_region=~'.*'}) / 60`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy3(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `Requests: ${floatFormatterBy3(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Stream Ingest API Responses By HTTP Code',
        showLegend: true,
        yAxisLabel: 'Request/Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '2xx',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `sum by (tecton_cluster)  (aws_applicationelb_httpcode_target_2_xx_count_sum{tecton_cluster=~'.*',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) / 60`
                  : `sum by (tecton_cluster) (aws_applicationelb_httpcode_target_2_xx_count_sum{tecton_cluster=~'.*', aws_region=~'.*'}) / 60`;
              return query;
            },
          },
          {
            label: '4xx',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `sum by (tecton_cluster)  (aws_applicationelb_httpcode_target_4_xx_count_sum{tecton_cluster=~'.*',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) / 60`
                  : `sum by (tecton_cluster) (aws_applicationelb_httpcode_target_4_xx_count_sum{tecton_cluster=~'.*', aws_region=~'.*'}) / 60`;
              return query;
            },
          },
          {
            label: '429',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `sum by (tecton_cluster) (aws_lambda_throttles_sum{tecton_cluster=~'.*', aws_region=~'.*'}) / 60`
                  : `sum by (tecton_cluster) (aws_lambda_throttles_sum{tecton_cluster=~'.*', aws_region=~'.*'}) / 60`;

              return query;
            },
          },
          {
            label: '5xx',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `sum by (tecton_cluster) (aws_applicationelb_httpcode_target_5_xx_count_sum{tecton_cluster=~'.*', aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) / 60`
                  : `sum by (tecton_cluster) (aws_applicationelb_httpcode_target_5_xx_count_sum{tecton_cluster=~'.*', aws_region=~'.*'}) / 60`;

              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy3(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label}: ${floatFormatterBy3(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Stream Ingest API Latency',
    graphs: [
      {
        chartTitle: 'Stream Ingest API Latency',
        showLegend: true,
        yAxisLabel: 'Duration(s)',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p50_00{tecton_cluster=~'.*g',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p50_00{tecton_cluster=~'.*g', aws_region=~'.*'}) by (tecton_cluster)`;

              return query;
            },
          },
          {
            label: '90.0 percentile',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p90_00{tecton_cluster=~'.*',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p90_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;

              return query;
            },
          },
          {
            label: '95.0 percentile',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p95_00{tecton_cluster=~'.*',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p95_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;

              return query;
            },
          },
          {
            label: '99.0 percentile',
            query: ({ ingressType }) => {
              const query =
                ingressType === 'alb'
                  ? `max(aws_applicationelb_target_response_time_p99_00{tecton_cluster=~'.*',  aws_region=~'.*', target_group=~'.*push.*|.*stream-ingest-api.*'}) by (tecton_cluster)`
                  : `max(aws_applicationelb_target_response_time_p99_00{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)} s`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const { dataset } = context;
          const labelText = `${dataset.label}: ${floatFormatterBy2(y)} s`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Rows Written',
    graphs: [
      {
        chartTitle: 'Online Store Row Write Rate',
        showLegend: true,
        yAxisLabel: 'Rows Written/Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Online Rows',
            query: () => {
              return `( (sum(rate(ingestapi_online_row_count[5m])) by (tecton_cluster) )  + (sum(ingestapi_online_ingest_row_write_count_sum) by (tecton_cluster) / 60))
              or (sum(rate(ingestapi_online_row_count[5m])) by (tecton_cluster) )
              or (sum(ingestapi_online_ingest_row_write_count_sum) by (tecton_cluster) / 60)`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `Online Rows: ${floatFormatterBy2(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Offline Store Row Write Rate',
        showLegend: true,
        yAxisLabel: 'Rows Written/Second',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Offline Rows',
            query: () => {
              return `sum by (tecton_cluster)  (ingestapi_offline_ingest_row_write_count_sum{tecton_cluster=~'.*',  aws_region=~'.*'}) / 60`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `Offline Rows: ${floatFormatterBy2(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Utilization',
    graphs: [
      {
        chartTitle: 'Concurrency Utilization (% of Default Limit)',
        showLegend: true,
        yAxisLabel: 'Utilization(%)',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Utilization',
            query: () => {
              // Multiply to by 100 to turn fraction of 1 into a percent.
              return `100 * sum(aws_lambda_concurrent_executions_maximum{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster) / sum(ingest_lambda_max_concurrent_functions{tecton_cluster=~'.*', aws_region=~'.*'}) by (tecton_cluster)`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy3(value)}`;
        },
        toolTipLabelCallback: (context: any) => {
          const { y } = context.parsed;
          const labelText = `Utilization: ${floatFormatterBy3(y)}`;
          return labelText;
        },
        parseValue: parseFloat,
      },
    ],
  },
];

/** ------------------------- Feature Service (FS details page) End ------------------------ */

/** ------------------------- Feature Server Group Start ------------------------ */

export const TRANSFORMATION_SERVER_GROUPS: GraphPanel[] = [
  {
    panelLabel: 'Requests',
    graphs: [
      {
        chartTitle: 'Request Rate',
        showLegend: true,
        yAxisLabel: 'Queries per Seconds',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ workspace, serverGroupId }) => {
              return `sum by (grpc_method)  (rate(grpc_server_handled_total{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} rd/s`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          const labelText = `Request Rate: ${label} : ${y} rd/s`;
          return labelText;
        },
        parseValue: parseInt,
        getMetricLabelCallback: (result) => {
          return `Request Rate: ${result.metric.grpc_method}`;
        },
      },
      {
        chartTitle: 'Request Handling Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: ({ workspace, serverGroupId }) => {
              const query = `histogram_quantile(0.5, sum(rate(grpc_server_handling_seconds_bucket{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '90.0 percentile',
            query: ({ workspace, serverGroupId }) => {
              const query = `histogram_quantile(0.9, sum(rate(grpc_server_handling_seconds_bucket{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '95.0 percentile',
            query: ({ workspace, serverGroupId }) => {
              const query = `histogram_quantile(0.95, sum(rate(grpc_server_handling_seconds_bucket{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '99.0 percentile',
            query: ({ workspace, serverGroupId }) => {
              const query = `histogram_quantile(0.99, sum(rate(grpc_server_handling_seconds_bucket{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y * 1000)} ms`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Response Percentage By Code',
        showLegend: true,
        yAxisLabel: 'Response Percentage',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ workspace, serverGroupId }) => {
              const query = `(sum(rate(grpc_server_handled_total{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}])) by (grpc_code) / ignoring(grpc_code) group_left sum(rate(grpc_server_handled_total{ aws_region=~'.*', workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}', grpc_method='Evaluate', tecton_cluster=~'.*'}[${interval}]))) * 100`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}%`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          return `${label}: ${floatFormatterBy2(y)}%`;
        },
        getMetricLabelCallback: (result) => {
          return `${result.metric.grpc_code}`;
        },
        parseValue: parseInt,
      },
      {
        chartTitle: 'Concurrent Request Limit Usage',
        showLegend: true,
        yAxisLabel: '% Concurrent Request Usage',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Average',
            query: ({ workspace, serverGroupId }) => {
              const query = `avg(transform_server_request_utilization_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, transform_server_group)`;
              return query;
            },
          },
          {
            label: 'Min',
            query: ({ workspace, serverGroupId }) => {
              const query = `min(transform_server_request_utilization_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, transform_server_group)`;
              return query;
            },
          },
          {
            label: 'Max',
            query: ({ workspace, serverGroupId }) => {
              const query = `max(transform_server_request_utilization_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, transform_server_group)`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}%`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y)}%`;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Node Count',
    graphs: [
      {
        chartTitle: '',
        showLegend: true,
        yAxisLabel: 'Node Count',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ workspace, serverGroupId }) => {
              return `count(count(transform_server_request_utilization_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (host, transform_server_group)) by (tecton_cluster, transform_server_group)`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value}`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          const labelText = `${label} : ${y}`;
          return labelText;
        },
        parseValue: parseInt,
        getMetricLabelCallback: (result) => {
          return `${result.metric.transform_server_group}`;
        },
      },
      {
        chartTitle: 'CPU Utilization (Percentage)',
        showLegend: true,
        yAxisLabel: '% CPU Utilization',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Average',
            query: ({ workspace, serverGroupId }) => {
              const query = `avg(transform_server_cpu_usage_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, workspace)`;
              return query;
            },
          },
          {
            label: 'Min',
            query: ({ workspace, serverGroupId }) => {
              const query = `min(transform_server_cpu_usage_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, workspace)`;
              return query;
            },
          },
          {
            label: 'Max',
            query: ({ workspace, serverGroupId }) => {
              const query = `max(transform_server_cpu_usage_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, workspace)`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}%`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y)}%`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Memory Usage (Percentage)',
        showLegend: true,
        yAxisLabel: 'Memory Usage (Percentage)',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ workspace, serverGroupId }) => {
              return `avg(transform_server_memory_usage_percentage{workspace=~'${workspace}', transform_server_group=~'tecton-tsg-${serverGroupId}',  aws_region=~'.*', tecton_cluster=~'.*'}) by (tecton_cluster, transform_server_group)`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}%`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          const labelText = `${label} : ${floatFormatterBy2(y)}%`;
          return labelText;
        },
        parseValue: parseFloat,
        getMetricLabelCallback: (result) => {
          return `${result.metric.transform_server_group}`;
        },
      },
    ],
  },
];

export const FEATURE_SERVER_GROUPS: GraphPanel[] = [
  {
    panelLabel: 'Requests',
    graphs: [
      {
        chartTitle: 'Requests by Method',
        showLegend: true,
        yAxisLabel: 'Queries per Seconds',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ serverGroupName }) => {
              return `sum by (grpc_method)  (rate(grpc_server_handled_total_per_server_group{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} rd/s`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          const labelText = `${label} : ${y} rd/s`;
          return labelText;
        },
        parseValue: parseInt,
        getMetricLabelCallback: (result) => {
          return `${result.metric.grpc_method}`;
        },
      },
      {
        chartTitle: 'Requests by Region',
        showLegend: true,
        yAxisLabel: 'Queries per Seconds',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ serverGroupName }) => {
              return `sum by (aws_region) (rate(grpc_server_handled_total_per_server_group{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}]))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} rd/s`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          const labelText = `${label} : ${y} rd/s`;
          return labelText;
        },
        parseValue: parseInt,
        getMetricLabelCallback: (result) => {
          return `${result.metric.aws_region}`;
        },
      },
      {
        chartTitle: 'Concurrent Request Limit Usage',
        showLegend: true,
        yAxisLabel: 'Request % of Limit',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Average',
            query: ({ serverGroupName }) => {
              const query = `min(concurrent_requests_max_percentage{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'})`;
              return query;
            },
          },
          {
            label: 'Min',
            query: ({ serverGroupName }) => {
              const query = `avg(concurrent_requests_max_percentage{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'})`;
              return query;
            },
          },
          {
            label: 'Max',
            query: ({ serverGroupName }) => {
              const query = `max(concurrent_requests_max_percentage{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'})`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value)}%`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y)}%`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Requests by Service',
        showLegend: true,
        yAxisLabel: 'Queries per Seconds',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '',
            query: ({ serverGroupName }) => {
              return `sum(rate(feature_service_query_count{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}])) by (feature_service_name, workspace, feature_service_id, server_group)`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value} rd/s`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;
          const labelText = `${label} : ${y} rd/s`;
          return labelText;
        },
        parseValue: parseInt,
        getMetricLabelCallback: (result) => {
          return `${result.metric.feature_service_name}`;
        },
      },
      {
        chartTitle: 'Feature Server Node Count by Status',
        showLegend: true,
        yAxisLabel: 'Node Count by Status',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: 'Available Servers',
            query: ({ serverGroupName }) => {
              return `(sum(server_group_nodes_available{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}) by (aws_region, server_group))`;
            },
          },
          {
            label: 'Unavailable Servers',
            query: ({ serverGroupName }) => {
              return `(sum(server_group_nodes_unavailable{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}) by (aws_region, server_group))`;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${value}`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          const labelText = `${label} : ${y}`;
          return labelText;
        },
        parseValue: parseInt,
      },
    ],
  },
  {
    panelLabel: 'Latency & Capacity',
    graphs: [
      {
        chartTitle: '(Get|Query)Feature Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.5, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{ aws_region=~'.*', server_group=~'${serverGroupName}', grpc_method=~'(GetFeatures|QueryFeatures|GetFeaturesV2)', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '90.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.9, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{ aws_region=~'.*', server_group=~'${serverGroupName}', grpc_method=~'(GetFeatures|QueryFeatures|GetFeaturesV2)', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '95.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.95, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{ aws_region=~'.*', server_group=~'${serverGroupName}', grpc_method=~'(GetFeatures|QueryFeatures|GetFeaturesV2)', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '99.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.99, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{ aws_region=~'.*', server_group=~'${serverGroupName}', grpc_method=~'(GetFeatures|QueryFeatures|GetFeaturesV2)', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y * 1000)} ms`;
        },
        parseValue: parseFloat,
      },
    ],
  },
  {
    panelLabel: 'Batch API',
    graphs: [
      {
        chartTitle: 'GetFeaturesBatch Latency',
        showLegend: true,
        yAxisLabel: 'Latency',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.5, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{grpc_method=~'(GetFeaturesBatch|GetFeaturesBatchV2)',  aws_region=~'.*', server_group=~'${serverGroupName}', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '90.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.9, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{grpc_method=~'(GetFeaturesBatch|GetFeaturesBatchV2)',  aws_region=~'.*', server_group=~'${serverGroupName}', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '95.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.95, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{grpc_method=~'(GetFeaturesBatch|GetFeaturesBatchV2)',  aws_region=~'.*', server_group=~'${serverGroupName}', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '99.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.99, sum(rate(grpc_server_handling_seconds_bucket_per_server_group{grpc_method=~'(GetFeaturesBatch|GetFeaturesBatchV2)',  aws_region=~'.*', server_group=~'${serverGroupName}', tecton_cluster=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y * 1000)} ms`;
        },
        parseValue: parseFloat,
      },
      {
        chartTitle: 'Feature Service Query Batch Size',
        showLegend: true,
        yAxisLabel: 'Keys',
        renderType: GraphRenderType.PROMETHEUS,
        queries: [
          {
            label: '50.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.5, sum(rate(feature_service_query_batch_size_bucket_per_server_group{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '90.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.9, sum(rate(feature_service_query_batch_size_bucket_per_server_group{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '95.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.95, sum(rate(feature_service_query_batch_size_bucket_per_server_group{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
          {
            label: '99.0 percentile',
            query: ({ serverGroupName }) => {
              const query = `histogram_quantile(0.99, sum(rate(feature_service_query_batch_size_bucket_per_server_group{tecton_cluster=~'.*', server_group=~'${serverGroupName}',  aws_region=~'.*'}[${interval}])) by (le))`;
              return query;
            },
          },
        ],
        yTickCallBack: (value: number) => {
          return `${floatFormatterBy2(value * 1000)} ms`;
        },
        toolTipLabelCallback: (context) => {
          const {
            parsed: { y },
            dataset: { label },
          } = context;

          return `${label}: ${floatFormatterBy2(y * 1000)} ms`;
        },
        parseValue: parseFloat,
      },
    ],
  },
];

/** ------------------------- Feature Server Group End ------------------------ */
